import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import { Article, ArticleWrapper, CommunityCard, NewSpace, SectionFourContent, SectionFourWrapper, SectionOneContent, SectionOneWrapper, SectionThreeContent, SectionThreeWrapper, SectionTwoContent, SectionTwoWrapper, Wrapper } from './style'
import { FiChevronRight } from 'react-icons/fi'
import { BiBullseye } from 'react-icons/bi'
import { BsArrowRight } from 'react-icons/bs'

const HomeScreen: React.FC = ()  => {
    return (
        <>
            <Header />
            <Wrapper>
                <SectionOneWrapper>
                    <SectionOneContent>
                        <div className="row-1">
                            <h3>About Us - New Horizons Products Colony</h3>

                            <p>Welcome to New Horizons Products Colony, your trusted partner in the world of Information Technology (IT) services and consulting. We're not just a company; we're your pathway to a brighter digital future.</p>

                            <a href="#">
                                <span>Learn more</span>
                                <BsArrowRight />
                            </a>
                        </div>

                        <div className="row-2">
                            <div className="img-content">
                                <img src="/assets/img/mission.jpg" alt="" />
                            </div>
                            <div className="text-content">
                                <h3>Our Mission</h3>

                                <p>At New Horizons Products Colony, our mission is simple yet profound: to help you navigate the ever-changing IT landscape with confidence. We understand that technology is at the heart of modern business, and it's our passion to empower you with the knowledge and solutions you need to succeed.</p>

                                <ul>
                                    <li>
                                        <BiBullseye />
                                        <span>Fast</span>
                                    </li>
                                    <li>
                                        <BiBullseye />
                                        <span>Reliable</span>
                                    </li>
                                    <li>
                                        <BiBullseye />
                                        <span>Excelent</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </SectionOneContent>
                </SectionOneWrapper>

                <SectionTwoWrapper>
                    <SectionTwoContent>
                        <h2>Who We Are</h2>
                        <h3>We are a team of dedicated IT professionals with decades of collective experience. Our expertise spans a wide range of IT domains, including:</h3>

                        <div className="products-wrapper">
                            <div className="products">
                                <h3>Consulting</h3>
                                <p>We provide strategic guidance to help you make informed decisions about your IT infrastructure, ensuring that your technology aligns with your business objectives.</p>
                                
                                {/* <div className="icon-box">
                                    <FiChevronRight />
                                </div> */}
                            </div>
                            <div className="products">
                                <h3>Development</h3>
                                <p>Our skilled developers create customized software solutions that cater to your unique needs, from web applications to mobile apps and beyond.</p>
                                
                                {/* <div className="icon-box">
                                    <FiChevronRight />
                                </div> */}
                            </div>
                            <div className="products">
                                <h3>Managed Services</h3>
                                <p>Our proactive approach to IT management ensures your systems run smoothly, minimizing downtime and maximizing productivity.</p>
                                
                                {/* <div className="icon-box">
                                    <FiChevronRight />
                                </div> */}
                            </div>
                            <div className="products">
                                <h3>Cybersecurity</h3>
                                <p>Protecting your digital assets is our top priority. We offer cutting-edge cybersecurity solutions to keep your data safe from evolving threats.</p>
                                
                                {/* <div className="icon-box">
                                    <FiChevronRight />
                                </div> */}
                            </div>
                            <div className="products">
                                <h3>Cloud Solutions</h3>
                                <p>Embrace the flexibility and scalability of the cloud with our expert guidance and implementation services.</p>
                                
                                {/* <div className="icon-box">
                                    <FiChevronRight />
                                </div> */}
                            </div>
                        </div>
                    </SectionTwoContent>
                </SectionTwoWrapper>

                <SectionThreeWrapper>
                    <SectionThreeContent>
                        <div className="content">
                            <h1>Join Us on the Journey</h1>
                            <p>
                                The IT landscape is ever-evolving, and we invite you to embark on this exciting journey with us. Whether you're a small business looking to streamline your operations or a large enterprise seeking to innovate and scale, New Horizons Products Colony is your dedicated partner every step of the way.
                                <br /><br /> Explore our portfolio, discover our range of services, and get in touch with us to discuss how we can transform your IT vision into reality. Together, let's navigate the digital horizon and shape a brighter future for your business.
                            </p>
                            <a href="#">Get Started</a>
                        </div>
                    </SectionThreeContent>
                </SectionThreeWrapper>

                <SectionFourWrapper>
                    <SectionFourContent>
                        <h2>Why Choose Us?</h2>

                        <div className="content">  
                            <div className="img-content">
                                <img src="/assets/svg/choose.svg" alt="" />
                            </div>
                            <div className="products-wrapper">
                                <div className="products">
                                    <h3>Expertise</h3>
                                    <p>Our team comprises certified professionals who are passionate about staying up-to-date with the latest industry trends and technologies.</p>
                                    
                                    {/* <div className="icon-box">
                                        <FiChevronRight />
                                    </div> */}
                                </div>
                                <div className="products">
                                    <h3>Customer-Centric</h3>
                                    <p>Your success is our success. We prioritize understanding your unique needs and delivering tailored solutions that exceed your expectations.</p>
                                    
                                    {/* <div className="icon-box">
                                        <FiChevronRight />
                                    </div> */}
                                </div>
                                <div className="products">
                                    <h3>Integrity</h3>
                                    <p>Honesty and transparency are the cornerstones of our business. You can trust us to provide unbiased advice and reliable services.</p>
                                    
                                    {/* <div className="icon-box">
                                        <FiChevronRight />
                                    </div> */}
                                </div>
                                <div className="products">
                                    <h3>Innovation</h3>
                                    <p>We thrive on innovation and continually seek out new ways to enhance your IT capabilities.</p>
                                    
                                    {/* <div className="icon-box">
                                        <FiChevronRight />
                                    </div> */}
                                </div>
                                <div className="products">
                                    <h3>Long-Term Partnerships</h3>
                                    <p>We aim to build enduring partnerships with our clients, providing ongoing support as your IT needs evolve.</p>
                                    
                                    {/* <div className="icon-box">
                                        <FiChevronRight />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </SectionFourContent>
                </SectionFourWrapper>
            </Wrapper>
            <Footer />
        </>
    )
}

export default HomeScreen