import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    primaryColor: string;
    secondaryColor: string;
    lightPrimaryColor: string;
  }
}

export const lightTheme: DefaultTheme = {
	primaryColor: "#31cdb0",
	secondaryColor: "#9C4DF4",
  lightPrimaryColor: "#f0f5ff"
};

export const darkTheme: DefaultTheme = {
	primaryColor: "#31cdb0",
	secondaryColor: "#cacaca",
  lightPrimaryColor: "#f0f5ff"
};